


import Component from 'vue-class-component';
import {Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {ScheduleType, TDateSchedule, TEventDay} from '@/_types/meeting/meeting.type';
@Component({
  name: 'meetings',
  components: {
  },
  computed: {
    ...mapGetters({
      getMeetingsScheduleDate: 'meetingsStore/getMeetingsSelectedDate',
      getContactSelectedDate: 'meetingsStore/getContactSelectedDate',
      getSidebarContactSelectedDate: 'meetingsStore/getSidebarContactSelectedDate',
      getSidebarScheduleDate: 'meetingsStore/getSidebarScheduleSelectedDate'
    })
  }
})

export default class MeetingsHead extends Vue {

  public getMeetingsScheduleDate: TEventDay;
  public getContactSelectedDate: TEventDay;
  public getSidebarContactSelectedDate: TEventDay;
  public getSidebarScheduleDate: TDateSchedule;
  public month: number = null;
  public week: number = null;

  @Prop({ type: Array, default: null })
  public readonly eventDays: TEventDay[];

  @Prop({ type: String, default: ScheduleType.CONTACT_SCHEDULE })
  public readonly type: ScheduleType;

  public get selectedDate(): TEventDay | TDateSchedule {
    if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
      this.week = this.getSidebarScheduleDate.week;
      this.month = this.getSidebarScheduleDate.month;
      return this.getSidebarScheduleDate;
    } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {

      this.week = this.getContactSelectedDate.week;
      this.month = this.getContactSelectedDate.month;
      return this.getContactSelectedDate;
    } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
      this.week = this.getSidebarContactSelectedDate.week;
      this.month = this.getSidebarContactSelectedDate.month;
      return this.getSidebarContactSelectedDate;
    } else {
      this.week = this.getMeetingsScheduleDate.week;
      this.month = this.getMeetingsScheduleDate.month;
      return this.getMeetingsScheduleDate;
    }

  }

  public get selectedMonthName(): string {
    return (this.selectedDate && this.selectedDate.monthName);
  }

  public get selectedMonth(): TEventDay[] {
    return this.eventDays.filter(item => {
      return this.month === item.month;
    });
  }

  public get selectedWeek(): TEventDay[] {
    return this.eventDays.filter(item => {
      return this.week === item.week;
    });
  }

  public get isShowRightArrow(): boolean {

    if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
      return (this.selectedDate && this.selectedDate.week) < (this.eventDays && this.eventDays.length && this.eventDays[this.eventDays.length - 1].week);
    } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
      return (this.selectedDate && this.selectedDate.week) < (this.eventDays && this.eventDays.length && this.eventDays[this.eventDays.length - 1].week);
    } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
      return (this.selectedDate && this.selectedDate.week) < (this.eventDays && this.eventDays.length && this.eventDays[this.eventDays.length - 1].week);
    } else {
      return (this.selectedDate && this.selectedDate.week) < (this.eventDays && this.eventDays.length && this.eventDays[this.eventDays.length - 1].week);
    }

  }

  public get isShowLeftArrow(): boolean {

    if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
      return (this.selectedDate && this.selectedDate.week) > (this.eventDays[0] && this.eventDays[0].week);
    } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
      return (this.selectedDate && this.selectedDate.week) > (this.eventDays[0] && this.eventDays[0].week);
    } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
      return (this.selectedDate && this.selectedDate.week) > (this.eventDays[0] && this.eventDays[0].week);
    } else {
      return (this.selectedDate && this.selectedDate.week) > (this.eventDays[0] && this.eventDays[0].week);
    }

  }

  public onDateRightArrowClick(): void {
    if (this.week < this.eventDays[this.eventDays.length - 1].week) {
      this.week++;
      if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
        this.$store.dispatch('meetingsStore/sidebarScheduleDate', {...this.selectedWeek[0]});
      } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
        this.$store.dispatch('meetingsStore/contactScheduleDate', {...this.selectedWeek[0]});
      } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
        this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', {...this.selectedWeek[0]});
      } else {
        this.$store.dispatch('meetingsStore/meetingsScheduleDate', {...this.selectedWeek[0]});
      }
      // this.selectedDate = {...this.selectedWeek[0]};
    }
  }

  public onDateLeftArrowClick(): void {
    if (this.week >= this.eventDays[0].week) {
      this.week--;
      if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
        this.$store.dispatch('meetingsStore/sidebarScheduleDate', {...this.selectedWeek[0]});
      } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
        this.$store.dispatch('meetingsStore/contactScheduleDate', {...this.selectedWeek[0]});
      } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
        this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', {...this.selectedWeek[0]});
      } else {
        this.$store.dispatch('meetingsStore/meetingsScheduleDate', {...this.selectedWeek[0]});
      }
      // this.selectedDate = {...this.selectedWeek[0]};
    }
  }

  public onMonthRightArrowClick(): void {
    if (this.month < this.eventDays[this.eventDays.length - 1].month) {
      this.month++;
      this.week = this.selectedMonth[0].week;

      if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
        this.$store.dispatch('meetingsStore/sidebarScheduleDate', {...this.selectedMonth[0]});
      } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
        this.$store.dispatch('meetingsStore/contactScheduleDate', {...this.selectedMonth[0]});
      } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
        this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', {...this.selectedMonth[0]});
      } else {
        this.$store.dispatch('meetingsStore/meetingsScheduleDate', {...this.selectedMonth[0]});
      }
    }
  }

  public onMonthLeftArrowClick(): void {
    if (this.month > this.eventDays[0].month) {
      this.month--;
      this.week = this.selectedMonth[0].week;
      if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
        this.$store.dispatch('meetingsStore/sidebarScheduleDate', {...this.selectedMonth[0]});
      } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
        this.$store.dispatch('meetingsStore/contactScheduleDate', {...this.selectedMonth[0]});
      } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
        this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', {...this.selectedMonth[0]});
      } else {
        this.$store.dispatch('meetingsStore/meetingsScheduleDate', {...this.selectedMonth[0]});
      }
    }
  }

  public get isMonthRightArrowDisabled(): boolean {
    if(this.month && this.eventDays[this.eventDays.length - 1].month) {
      return (this.month >= this.eventDays[this.eventDays.length - 1].month);
    }
    return true;
  }

  public get isMonthLeftArrowDisabled(): boolean {
    if(this.month && this.eventDays[0].month) {
      return (this.month <= this.eventDays[0].month);
    }
    return true;
  }

  public selectDay(dayObj: TEventDay): void {
    if(this.$parent.$options.name === 'side-bar-right-tab-schedule') {
      this.$store.dispatch('meetingsStore/sidebarScheduleDate', {...dayObj});
    } else if(this.type === ScheduleType.CONTACT_SCHEDULE) {
      this.$store.dispatch('meetingsStore/contactScheduleDate', {...dayObj});
    } else if(this.type === ScheduleType.SIDEBAR_CONTACT_INFO_SCHEDULE) {
      this.$store.dispatch('meetingsStore/sideBarContactScheduleDate', {...dayObj});
    } else {
      this.$store.dispatch('meetingsStore/meetingsScheduleDate', {...dayObj});
    }

  }
}

