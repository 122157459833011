
import { TContact } from '../contact.type';
import { MeetingStatus } from '@/_modules/meeting-rooms/types/meeting-status.enum';
import moment from 'moment';
import {TranslateResult} from 'vue-i18n';
import {TConferenceProgram} from '@/_modules/promo/types/conference-program.type';

export enum ScheduleItemType {
  MEETING = 'meeting',
  PROGRAM = 'program',
}

export enum ScheduleType {
  SIDEBAR_CONTACT_INFO_SCHEDULE = 'sidebar-contact-info-schedule',
  CONTACT_SCHEDULE = 'contact-schedule',
}

export type TScheduleItem = {
  startTime: moment.Moment;
  startTimeKey: string;
  startTimeText: string;
  endTime?: moment.Moment;
  endTimeKey?: string;
  endTimeText: string;
  type: ScheduleItemType;
  key: string;
  markerText: TranslateResult;
  meeting?: TMeeting;
  program?: TConferenceProgram;
};

export type TDateSchedule = {
  month: number;
  monthName: string;
  week: number;
  date: moment.Moment;
  dateKey: string;
  dayNumber: number;
  items: TScheduleItem[];
};

export type TMonthSchedule = {
  month: moment.Moment;
  monthKey: string;
  items: TDateSchedule[];
  selectedItem: TDateSchedule;
};

type TMeetingsError ={
  text?: string;
}

export type TEventDay = {
  month?: number;
  monthName?: string;
  week?: number;
  date: Date;
  dayNumber: string;
  badgeNotification?: boolean;
  items?: TScheduleItem;
}

type TTimeslotError ={
  text?: string;
}

export type TTimeSlot = {
  dateStart: Date;
  dateEnd: Date;
  meeting?: TMeeting;
  errors?: TTimeslotError[];
  isProcessing?: boolean;
}

export type TMeeting = {
  id: number;
  date_start: Date;
  date_end: Date;
  duration_min: number;
  title?: string;
  communication_type?: string;
  communication_data?: string;
  contact: TContact;
  status: MeetingStatus;
  is_mine: boolean;
  is_creator: boolean;
  creator_contact: TContact;
  user_contact: TContact;
  errors?: TMeetingsError[];
  isFading?: boolean;
  isFolding?: boolean;
}
